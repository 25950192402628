import React, {Component} from 'react';
import ReactDOM from 'react-dom'

class FacebookLogin extends React.Component {

	handleClick = (event) => {
		event.preventDefault();

		let url = "/facebook-login?redirect[path]=/"
		
		if( document.getElementById( "optout_marketing" ) ){
			const checked = document.getElementById( "optout_marketing" ).checked
			url = `${url}&redirect[optout_marketing]=${checked ? 1 : 0}&redirect[skip]=1`;
		}
		 
		const width = 400;
		const height = 600;
		const left = (screen.width/2)-(width/2);
		const top = (screen.height/2)-(height/2);
		const externalWindow = window.open( url, 'oauthFB', "menubar=no,status=no,width="+width+",height="+height+",toolbar=no,left="+left+",top="+top);
	}
	
	render() {
		
		const sizeClass = this.props.size == 'small' ? 'cancellation-small' : 'cancellation';

		return (
			<a className="button" onClick={this.handleClick}>Login with Facebook</a>
		)
	}
}

document.addEventListener('DOMContentLoaded', () => {
	const fbLogin = document.querySelector('#cpt-facebook-login');
	const redirect = fbLogin.getAttribute('redirect');
	ReactDOM.render( <FacebookLogin redirect={redirect} />, fbLogin )
})
